import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.jpg'
import IMG2 from '../../assets/portfolio2.jpg'
import IMG3 from '../../assets/portfolio3.jpg'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.png'
import IMG6 from '../../assets/portfolio6.jpg'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Test Title One',
    github: 'https://github.com',
    demo: 'https://dribbble.com/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Test Title Two',
    github: 'https://github.com',
    demo: 'https://dribbble.com/'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Test Title Three',
    github: 'https://github.com',
    demo: 'https://dribbble.com/'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Test Title Four',
    github: 'https://github.com',
    demo: 'https://dribbble.com/'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Test Title Five',
    github: 'https://github.com',
    demo: 'https://dribbble.com/'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Test Title Six',
    github: 'https://github.com',
    demo: 'https://dribbble.com/'
  },
]

const Portfolio = () => {
  return (
    <section id='portfolio' className='section-p-tb bg-color-1'>
      <div className="container">
        <div className="section-title">
          <h4>My Recent Works</h4>
          <h2>Portfolio</h2>
        </div>

        <div className="portfolio__container">
          {
            data.map(({id, image, title, github, demo}) => {
              return(
                <article key={id} className='portfolio__item'>
                  <div className="portfolio__item-image">
                    <img src={image} className='img-fluid' alt={title} />
                  </div>
                  <h3>{title}</h3>
                  <div className="portfolio__item-cta">
                    <a href={github} className='btn' target='_blank'>Github</a>
                    <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                  </div>
                </article>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default Portfolio