import React, { useState } from 'react'
import Main from './components/main/Main'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Portfolio from './components/portfolio/Portfolio'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import {createContext} from 'react'
import Switch from 'react-switch'
import { MdLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";

export const ThemeContext = createContext(null);

const App = () => {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((curr) => (curr === 'dark' ? 'light' : 'dark'))
  }

  return (
    <ThemeContext.Provider value={{theme, toggleTheme}}>
      <div className={`color-theme-wrapper ${theme === 'light' ? 'light-mode' : 'dark-mode'}`} id={theme}>
        <Main />
        <Switch 
        onChange={toggleTheme} 
        checked={theme === 'light'}
        uncheckedIcon={<MdLightMode />}
        checkedIcon={<MdDarkMode />}
        className='color-mode-switch'
        />
        <Nav />
        <About />
        <Experience />
        <Services />
        <Portfolio />
        {/* <Testimonials /> */}
        <Contact />
        <Footer />
      </div>
    </ThemeContext.Provider>
  )
}

export default App