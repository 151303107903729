import React from 'react'
import './about.css'
import ME from "../../assets/me-about.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id='about' className='about-section bg-color-2 section-p-tb'>
      <div className="container">
        <div className="section-title">
          <h4>Get To Know</h4>
          <h2>About Me</h2>
        </div>

        <div className="about__container">
          <div className="about__me">
            <div className="about__me-image">
              <img src={ME} className="img-fluid" alt="About Image" />
            </div>
          </div>
          <div className="about__content">
            <div className="about__cards">
              <article className='about__card'>
                <FaAward className='about__icon'/>
                <h4>Experience</h4>
                <small>3+ Years Working</small>
              </article>

              <article className='about__card'>
                <FiUsers className='about__icon'/>
                <h4>Clients</h4>
                <small>200+ Worldwide</small>
              </article>

              <article className='about__card'>
                <VscFolderLibrary className='about__icon'/>
                <h4>Projects</h4>
                <small>80+ Completed</small>
              </article>
            </div>

            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore, sapiente aliquam, laudantium libero ipsam esse, sequi reiciendis dicta a nihil error! Quisquam, asperiores ratione iure blanditiis in porro assumenda non.</p>

            <a href="#contact" className='btn btn-primary'>Let's Talk</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About