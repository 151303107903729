import React from 'react'
import './main.css'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'
import {BsCodeSlash} from "react-icons/bs";
import {CgWebsite} from "react-icons/cg";
import {DiPhotoshop} from "react-icons/di";
import {MdEngineering} from "react-icons/md";
import {MdKeyboardDoubleArrowDown} from "react-icons/md";
import ME from '../../assets/me.png';

const MainSection = () => {
  return (
    <section className="section-main bg-color-1">
      <div className="container">
		<div className="position-relative">
			<div className="home-content">
				<h2><span>Hello</span>, I am</h2>
				<h1>Ashan Vinod</h1>
				<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil saepe molestias iure ea pariatur hic. Non, officia est? Possimus corrupti ipsam tempora consequuntur suscipit esse exercitationem hic in blanditiis sed.</p>
				<CTA />
				<HeaderSocials />
			</div>
			<div className="profession-container">
				<div className="profession-box">
					<div className="profession" style={{"--i":0}}>
						<BsCodeSlash />
						<h3>Web Developer</h3>
					</div>
					<div className="profession" style={{"--i":1}}>
						<CgWebsite />
						<h3>UI Designer</h3>
					</div>
					<div className="profession" style={{"--i":2}}>
						<DiPhotoshop />
						<h3>Graphic Designer</h3>
					</div>
					<div className="profession" style={{"--i":3}}>
						<MdEngineering />
						<h3>Software Engineer</h3>
					</div>
					<div className="circle"></div>
				</div>
				<div className="overlay"></div>
				<div className="home-img">
					<img src={ME} alt="My Image" />
				</div>
			</div>
		</div>
		<a href="#contact" className='scroll__down'>Scroll Down <MdKeyboardDoubleArrowDown /></a>
      </div>
    </section>
  )
}

export default MainSection